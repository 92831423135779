"use client"; // This is a client-side component

import { useEffect, useState } from "react";
import Script from "next/script";

export default function GoogleAds({ adId }) {
  const [conversionTracked, setConversionTracked] = useState(false); // State to track if conversion is already fired

  return (
    <>
      {/* Load the Google Ads Script */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=AW-${adId}`}
        strategy="afterInteractive" // Ensures script is loaded after the page becomes interactive
        async
      />

      <Script
        id="google-ads-init"
        strategy="afterInteractive"
        async>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-${adId}');
        `}
      </Script>
    </>
  );
}
